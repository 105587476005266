import Base from "./base.js"

export default class Mews extends Base {
  constructor() {
    super()
    this.route = "/api/mews"
  }

  customer(location_id, last_name, email) {
    let self = this
    return new Promise(function (resolve, reject) {
      self
        .Axios({
          url: `${self.route}/customer`,
          data: {
            location_id: location_id,
            last_name: last_name,
            email: email,
          },
          method: "POST",
        })
        .then((response) => {
          // Resolve successfully
          resolve(response.data)
        })
        .catch((error) => {
          reject(self.parseErrorResponses(error.response.data))
        })
    })
  }

  customers(location_id, name) {
    let self = this
    return new Promise(function (resolve, reject) {
      self
        .Axios({
          url: `${self.route}/customers`,
          data: { location_id: location_id, name: name },
          method: "POST",
        })
        .then((response) => {
          // Resolve successfully
          resolve(response.data)
        })
        .catch((error) => {
          reject(self.parseErrorResponses(error.response.data))
        })
    })
  }

  services(location_id) {
    let self = this
    return new Promise(function (resolve, reject) {
      self
        .Axios({
          url: `${self.route}/services`,
          data: { location_id: location_id },
          method: "POST",
        })
        .then((response) => {
          // Resolve successfully
          resolve(response.data)
        })
        .catch((response) => {
          reject(self.parseErrorResponses(error.response.data))
        })
    })
  }
}
