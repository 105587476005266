import Base from "./base.js"

export default class Booking extends Base {
  constructor() {
    super()
    this.route = "/api/booking"
  }

  all(filter = null, page) {
    let self = this
    return new Promise(function (resolve, reject) {
      self
        .Axios({
          url: `${self.route}?page=${page}&filter=${filter}`,
          method: "GET",
        })
        .then((response) => {
          // Resolve successfully
          resolve(response.data)
        })
        .catch((response) => {
          reject(false)
        })
    })
  }

  open() {
    let self = this
    return new Promise(function (resolve, reject) {
      self
        .Axios({ url: self.route + "/open", method: "GET" })
        .then((response) => {
          // Resolve successfully
          resolve(response.data)
        })
        .catch((response) => {
          reject(false)
        })
    })
  }

  detail(id) {
    let self = this
    return new Promise(function (resolve, reject) {
      self
        .Axios({ url: self.route + `/${id}`, method: "GET" })
        .then((response) => {
          // Resolve successfully
          resolve(response.data)
        })
        .catch((response) => {
          reject(response.error)
        })
    })
  }

  create(data) {
    console.log("booking creation")
    console.log(data)
    let self = this
    return new Promise(function (resolve, reject) {
      self
        .Axios({ url: self.route, data: data, method: "POST" })
        .then((response) => {
          console.log(response.data)
          resolve(response.data)
        })
        .catch((error) => {
          reject(self.parseErrorResponses(error.response.data))
        })
    })
  }

  request(data) {
    console.log("booking request")
    console.log(data)
    let self = this
    return new Promise(function (resolve, reject) {
      self
        .Axios({ url: `${self.route}/request`, data: data, method: "POST" })
        .then((response) => {
          console.log(response.data)
          resolve(response.data)
        })
        .catch((error) => {
          reject(self.parseErrorResponses(error.response.data))
        })
    })
  }

  update(data) {
    console.log("booking updating")
    let self = this
    return new Promise(function (resolve, reject) {
      self
        .Axios({ url: self.route + `/${data.id}`, data: data, method: "PUT" })
        .then((response) => {
          console.log(response.data)
          resolve(response.data)
        })
        .catch((error) => {
          console.log(error)
          reject(self.parseErrorResponses(error.response.data))
        })
    })
  }

  delete(id) {
    let self = this
    return new Promise(function (resolve, reject) {
      self
        .Axios({ url: self.route + `/${id}`, method: "DELETE" })
        .then((response) => {
          // Resolve successfully
          resolve(true)
        })
        .catch((error) => {
          console.log(error.error)
          reject(false)
        })
    })
  }

  accept(id) {
    let self = this
    return new Promise(function (resolve, reject) {
      self
        .Axios({ url: `${self.route}/${id}/accept`, method: "POST" })
        .then((response) => {
          resolve(true)
        })
        .catch((error) => {
          console.log(error.error)
          reject(false)
        })
    })
  }

  deny(id) {
    let self = this
    return new Promise(function (resolve, reject) {
      self
        .Axios({ url: `${self.route}/${id}/deny`, method: "POST" })
        .then((response) => {
          resolve(true)
        })
        .catch((error) => {
          console.log(error.error)
          reject(false)
        })
    })
  }

  downloadContract(id) {
    console.log("download")
    let self = this
    return new Promise(function (resolve, reject) {
      self
        .Axios({
          url: self.route + `/${id}/download-contract`,
          method: "GET",
          responseType: "blob",
        })
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]))
          const link = document.createElement("a")
          link.href = url
          link.setAttribute("download", `booking-${id}.pdf`)
          document.body.appendChild(link)
          link.click()
          resolve(response.data)
        })
        .catch((response) => {
          reject(response.error)
        })
    })
  }

  processReturns(booking) {
    console.log("booking returns")
    let self = this
    let data = {
      booking_rows: booking.booking_rows,
    }
    return new Promise(function (resolve, reject) {
      self
        .Axios({
          url: self.route + `/${booking.id}/process-returns`,
          data: data,
          method: "POST",
        })
        .then((response) => {
          console.log(response.data)
          resolve(response.data)
        })
        .catch((error) => {
          console.log(error)
          reject(self.parseErrorResponses(error.response.data))
        })
    })
  }
}
