import Base from "./base.js"

export default class Customer extends Base {
  constructor() {
    super()
    this.route = "/api/customer"
  }

  all() {
    let self = this
    return new Promise(function (resolve, reject) {
      self
        .Axios({ url: self.route, method: "GET" })
        .then((response) => {
          // Resolve successfully
          resolve(response.data)
        })
        .catch((response) => {
          reject(self.parseErrorResponses(error.response.data))
        })
    })
  }

  byLocationId(id) {
    let self = this
    return new Promise(function (resolve, reject) {
      self
        .Axios({ url: self.route + `/location/${id}`, method: "GET" })
        .then((response) => {
          // Resolve successfully
          resolve(response.data)
        })
        .catch((response) => {
          reject(self.parseErrorResponses(error.response.data))
        })
    })
  }

  detail(id) {
    let self = this
    return new Promise(function (resolve, reject) {
      self
        .Axios({ url: self.route + `/${id}`, method: "GET" })
        .then((response) => {
          // Resolve successfully
          resolve(response.data)
        })
        .catch((response) => {
          reject(self.parseErrorResponses(error.response.data))
        })
    })
  }

  create(data) {
    let self = this
    return new Promise(function (resolve, reject) {
      self
        .Axios({ url: self.route, data: data, method: "POST" })
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          reject(self.parseErrorResponses(error.response.data))
        })
    })
  }

  update(data) {
    let self = this
    return new Promise(function (resolve, reject) {
      self
        .Axios({ url: self.route + `/${data.id}`, data: data, method: "PUT" })
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          reject(self.parseErrorResponses(error.response.data))
        })
    })
  }

  delete(id) {
    let self = this
    return new Promise(function (resolve, reject) {
      self
        .Axios({ url: self.route + `/${id}`, method: "DELETE" })
        .then((response) => {
          // Resolve successfully
          resolve(true)
        })
        .catch((response) => {
          reject(self.parseErrorResponses(error.response.data))
        })
    })
  }
}
